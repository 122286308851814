<template>
  <v-container>
    <v-row class="mt-16">
      <v-col cols="2"></v-col>
      <v-col cols="12" lg="8" class="text-center">
          <span class="text-h1" >
          {{ $t('welcomeMessage') }}</span>
      </v-col>
    </v-row>
    <v-row class="mt-16">
      <v-col cols="12" class="text-center">
        <v-btn class="primary mr-7" to="/client/registration" rounded="xl" size="x-large" elevation="10" >{{ $t('registerButton') }}</v-btn>
        <v-btn class="primary ml-7" to="/login" rounded="xl" size="x-large" elevation="10" >{{ $t('logIn') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
});
</script>
