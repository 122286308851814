export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy EBM'ye hoş geldiniz"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydol"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burada hesabınızı oluşturun"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri hesabı oluştur"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyadı"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KDV Numarası"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sokak"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehir"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posta kodu"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yarıçap"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web sitesinin kullanım şartlarını kabul ediyorum"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız oluşturuldu. Lütfen hesabınızı onaylamak için e-postanızı kontrol edin."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarı"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri grubu seçin"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedarikçi"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedarikçiler"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedarikçi siparişleri"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üretici"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usta"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojistikçi"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajans"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entegratör"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorunlu alan"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticaret Sicili Kaydı"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba giriş yap"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenizi mi unuttunuz?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu e-posta ile ilişkilendirilmiş bir hesap yok"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz şifre"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat adresi"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatura adresi"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siparişler"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş tarihi"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderim tarihi"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İrsaliyeler"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depo"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stok"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitap"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat adresi"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo seçeneği"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo tipi"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo türleri"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderen"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderim tarihi"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderici Detayları"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özellikler"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ev adresine teslim"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo tipi"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambalaj Alımı"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbest Kullanım Alanı"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 saat hizmet Pazartesi-Cuma."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 Saat Ücretsiz Servis SA."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48h Servisi"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta içi belirlenen tarihlerde teslimat."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumartesi teslimatı"])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akşam Teslimatı"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlı Kurulum Servisi"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo yöntemi"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslim"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal gönderim"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçmiş"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderi durumu"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketler"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koli"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderi verilerini kaydet"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi kaydet"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo seçenekleri"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depo teslimatı"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depo girişi"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderi takibi için"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderi pozisyonları"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kadın"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilinmeyen"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirket"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bey"])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalgıçlar"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderi siparişi oluştur"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi kaydet"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabirimler"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arayüzleriniz"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılabilir Arabirimler"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervasyon yap"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılandır"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaydet"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleştirmeyi Başlat"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenek"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["son güncelleme"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak değeri"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum tipi"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylemler"])},
  "Add Option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenek ekle"])},
  "check connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı test et"])},
  "interface statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arayüz İstatistikleri"])},
  "interface options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arayüz Seçenekleri"])},
  "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma alanı"])},
  "jobber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşçi"])},
  "Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alışveriş"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Oluşturma"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üretici"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depo"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İadeler"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gönderildi"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gönderilmedi"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı ekledikten sonra Collie'yi kaydedin"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haritayı buraya kaydet"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Yönetici"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale şablonu"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kargo için"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış yap"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değerlendirmeler"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takip & İzleme"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderi"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderiler"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsi"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son Eklenen"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklemede"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslim edildi"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorunlu durumlar"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İadeler"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugün"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dün"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hafta"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçen hafta"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 7 gün"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 14 gün"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 30 gün"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ay"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçen ay"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 3 ay"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 6 ay"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 12 ay"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulmadı"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri gönderildi"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimatta istisna"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorun"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderi numarası"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlayıcı (referans)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son etkinlik"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son Bilgi"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih eklendi"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre ayarlanmadı"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 14 günün özeti"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket eklendi"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En İyi Gönderenler (hacme dayalı)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açık Siparişler"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kar"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugünkü Siparişler"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugünkü cirosu"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakliye günlüğü"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesaj"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket oluşturuldu"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale Genel Bakışı"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Veri"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale adı"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale açıklaması"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojistik Veriler"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyatlar"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resimler"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makaleleri Senkronize Et"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale Proje Açıklama Şablonları"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Şablon Oluştur"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonu Kaydet"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon Adı"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale Projesi"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale Projesi Ekle"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeyi Kaydet"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedarikçi Ürün Adı"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre seti yok"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam sevkiyat"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Açıklaması"])},
    "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lojistik Verileri"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyatlar"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resimler"])},
    "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makaleleri Senkronize Et"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft 365 ile Giriş Yap"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme Genel Bakış"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme Ekle"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme Adı"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerlilik Başlangıç Tarihi"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerlilik Bitiş Tarihi"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif mi"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli mi"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri Grubu"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşmeyi Kaydet"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Durumu Seç"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Değil"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Durumu Seç"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Değil"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşmeyi Düzenle"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşmeyi Güncelle"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme Detayları"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okundu Olarak İşaretle"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Yükle"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırla"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Seç"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siparişleri Senkronize Et"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza Seç"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş Tarihi"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlama Durumu"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teslimat Yöntemi"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedarikçi Ekle"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedarikçi ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoksuz Satış"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Sitesi"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arayüz"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tedarikçi Siparişleri"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşür"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID'ler"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazdır"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML Şablon Oluşturucu"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizleme"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satıcı"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturan"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleyen"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale Özellik Değeri"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş Genel Bakış"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 15 Sipariş"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale Detayları"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklamalar"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makale Paketi"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstatistikler"])}
}