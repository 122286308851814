<template>
  <v-navigation-drawer
    :theme="theme.global.name.value"
    color="black"
    v-model="store.state.navigationFlag"
  >
    <v-list>
      <v-list-item two-line>
        <v-list-item-title>{{ store.state.userDetails.companyName }}</v-list-item-title>
        <v-list-item-subtitle>{{ store.state.userDetails.email }}</v-list-item-subtitle>
        <template v-slot:append>
          <v-btn variant="icon" @click="toggleTheme()"
            ><v-icon :style="theme.global.name.value == 'light' ? '' : 'color:orange;'">{{
              theme.global.name.value == "light"
                ? "mdi-moon-waning-crescent"
                : "mdi-weather-sunny"
            }}</v-icon></v-btn
          >
          <v-btn
            variant="plain"
            @click="$store.state.navigationFlag = !$store.state.navigationFlag"
            icon="mdi-dots-vertical"
          ></v-btn>
        </template>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
      <div id="simplebar-sidebar" class="js-sidebar-scroll">
        <slot name="content">
          <!-- Side Navigation -->
          <div class="content-side">
            <BaseNavigation :nodes="navigation" :dark=true />
          </div>
          <!-- END Side Navigation -->
        </slot>
      </div>
      <!-- <v-list-item
        class="mt-4"
        to="/dashboard"
        prepend-icon="mdi-view-dashboard"
        title="Dashboard"
      ></v-list-item>
      <v-list-item prepend-icon="mdi-account-box" to="/user_option" title="Account"></v-list-item>
      <v-list-item
        prepend-icon="mdi-account-key-outline"
        v-if="store.state.isSuperAdmin"
        @click="$store.state.clientDialog = true"
        title="Change Client"
      ></v-list-item>
      <h4 class="ml-4 mt-4">Manage</h4>
      <v-list-group value="Pim">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-format-paragraph"
            title="PIM"
          ></v-list-item>
        </template>
        <v-list-item to="/articles">
          <v-icon>mdi-package-variant-closed</v-icon>
          <span class="ml-4">{{ $t("articles") }}</span>
        </v-list-item>
        <v-list-item to="/articles_templates">
          <v-icon>mdi-image-text</v-icon>
          <span class="ml-4">{{ $t("articleTemplate") }}</span>
        </v-list-item>
        <v-list-item to="/categories">
          <v-icon>mdi-file-tree</v-icon>
          <span class="ml-4">{{ $t("categories") }}</span>
        </v-list-item>
        <v-list-item to="/articles/project">
          <v-icon>mdi-signature-image</v-icon>
          <span class="ml-4">{{ $t("Articleprojects") }}</span>
        </v-list-item>
        <v-list-item to="/articlefeatures">
          <v-icon>mdi-package-variant-closed</v-icon>
          <span class="ml-4">{{ $t("Articlefeatures") }}</span>
        </v-list-item>
        <v-list-item to="/articles/producers">
          <v-icon>mdi-cogs</v-icon>
          <span class="ml-4">{{ $t("producer") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Orm">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-basket" title="ORM"></v-list-item>
        </template>
        <v-list-item to="/orders">
          <v-icon>mdi-badge-account-horizontal</v-icon>
          <span class="ml-4">{{ $t("orders") }}</span>
        </v-list-item>
        <v-list-item to="/contacts">
          <v-icon>mdi-human-queue</v-icon>
          <span class="ml-4">{{ $t("Contacts") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Purchase">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-lightbulb-outline">
            <v-list-item-title>
              {{ $t("Purchase") }}
            </v-list-item-title></v-list-item
          >
        </template>
        <v-list-item to="/supplierorders">
          <v-icon>mdi-package-variant-closed</v-icon>
          <span class="ml-4">{{ $t("Supplierorders") }}</span>
        </v-list-item>
        <v-list-item to="/suppliers">
          <v-icon>mdi-factory</v-icon>
          <span class="ml-4">{{ $t("Suppliers") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Shipment">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-truck-fast">
            <v-list-item-title>
              {{ $t("shipping") }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item to="/deliverynotes">
          <v-icon>mdi-note-multiple-outline</v-icon>
          <span class="ml-4">{{ $t("deliverynotes") }}</span>
        </v-list-item>
        <v-list-item>
          <v-icon>mdi-package-variant-closed</v-icon>
          <span class="ml-4">{{ $t("Returns") }}</span>
        </v-list-item>
        <v-list-item to="/shippingarts">
          <v-icon>mdi-package-variant-closed</v-icon>
          <span class="ml-4">{{ $t("Shippingarts") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Trackandtrace">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-shipping-pallet">
            <v-list-item-title>
              {{ $t("TrackandTrace") }}
            </v-list-item-title></v-list-item
          >
        </template>
        <v-list-item to="/tt">
          <v-icon>mdi-truck-cargo-container</v-icon>
          <span class="ml-4">{{ $t("shipments") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Reporting">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-lightbulb-outline">
            <v-list-item-title>
              {{ $t("reporting") }}
            </v-list-item-title></v-list-item
          >
        </template>
        <v-list-item to="/reports">
          <v-icon>mdi-warehouse</v-icon>
          <span class="ml-4">{{ $t("reports") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Stock">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-lightbulb-outline">
            <v-list-item-title>
              {{ $t("Warehouse") }}
            </v-list-item-title></v-list-item
          >
        </template>
        <v-list-item to="/warehouse">
          <v-icon>mdi-warehouse</v-icon>
          <span class="ml-4">{{ $t("Warehouse") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Workspace">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-lightbulb-outline">
            <v-list-item-title>
              {{ $t("workspace") }}
            </v-list-item-title></v-list-item
          >
        </template>
        <v-list-item to="/jobber">
          <v-icon>mdi-warehouse</v-icon>
          <span class="ml-4">{{ $t("jobber") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group value="Settings">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-cog">
            <v-list-item-title>
              {{ $t("settings") }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item to="/myinterfaces">
          <v-icon>mdi-expansion-card</v-icon>
          <span class="ml-4">{{ $t("MyInterfaces") }}</span>
        </v-list-item>
        <v-list-item to="/dropy/modules">
          <v-icon>mdi-package-variant-closed</v-icon>
          <span class="ml-4">Dropy Modules</span>
        </v-list-item>
        <v-list-item to="/users">
          <v-icon>mdi-account</v-icon>
          <span class="ml-4">{{ $t("users") }}</span>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-icon>mdi-logout</v-icon>
          <span class="ml-4">{{ $t("logout") }}</span>
        </v-list-item>
      </v-list-group>
      <v-list-group v-if="store.state.isSuperAdmin" value="Super Admin">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-shield-account">
            <v-list-item-title>
              {{ $t("superAdmin") }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item to="/health_check">
          <v-icon>mdi-server</v-icon>
          <span class="ml-4">Health Check</span>
        </v-list-item>
        <v-list-item to="/health_check/phpinfo">
          <v-icon>mdi-math-log</v-icon>
          <span class="ml-4">Service Logs</span>
        </v-list-item>
        <v-list-item to="/public_images">
          <v-icon>mdi-image</v-icon>
          <span class="ml-4">Public Images</span>
        </v-list-item>
        <v-list-item to="/client_registration_fwf_new">
          <v-icon>mdi-account-plus-outline</v-icon>
          <span class="ml-4">Register Client</span>
        </v-list-item>
      </v-list-group> -->
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { useTheme } from "vuetify/lib/framework.mjs";
// import menu from "@/Utils/menu";
import { generateMenuItems } from "@/Utils/menu";


export default {
  setup(props) {
    const store = useStore();
    const theme = useTheme();
    const { setTheme } = useTheme();
    // const navigation = menu.main;
    const navigation = generateMenuItems(store.state); // Generate menu items using store state

    const data = reactive({
      loading: false,
    });
    const toggleTheme = () => {
      if (theme.global.name.value == "light") theme.global.name.value = "dark";
      else theme.global.name.value = "light";
      // Toggle between light and dark mode
      // setTheme({ ...theme, dark: !theme.dark });
    };
    // color="#203546"
    const logout = () => {
      store.state.token = null;
      localStorage.removeItem("jwt");
      store.state.isAuthenticated = false;
      store.state.userDetails.name = null;
      store.state.userDetails.email = null;
      store.state.userDetails.companyName = null;
      router.push({ path: "/" });
    };
    return {
      store,
      theme,
      navigation,
      toggleTheme,
      ...toRefs(data),
      logout,
    };
  },
};
</script>
