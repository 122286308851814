<template>
  <div class="table-responsive">
    <table class="table table-striped table-hover centered-table">
      <thead>
        <tr class="text-center">
          <th>
            {{ $t('channel') }}
          </th>
          <th>{{ $t('orm.order.orderId') }}</th>
          <th>{{ $t('date') }}</th>
          <th>{{ $t('Invoiceaddress') }}</th>
          <th>{{ $t('Deliveryaddress') }}</th>
          <th>{{ $t('orm.order.amount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(item, index) in this.$props.recentOrders" :key="index">
          <td>
            {{ item.channel }}
          </td>
          <td>
            <router-link :to="'/order/' + item.id">
              {{ item.ordernr }}<br>
              {{ item.orderextnr }}
            </router-link>
          </td>
          <td class="fw-semibold fs-sm">
            {{ formateDate(item.orderdate) }}
          </td>
          <td>
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="flat" v-bind="props">
                  <span v-if="item.invoiceaddress != null">
                    {{ (item.invoiceaddress.companyname == null || item.invoiceaddress.companyname.length == 0) ?
                      item.invoiceaddress.firstname.substring(0, 30) + ' ' + item.invoiceaddress.lastname : (
                        item.invoiceaddress.companyname.length > 30 ? (item.invoiceaddress.companyname.substring(0, 30) +
                          '...') : item.invoiceaddress.companyname) }}
                  </span>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card elevation="16" variant="outlined" style="border-radius: 10px;">
                <v-card-text>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.invoiceaddress.firstname + ' ' +
                    item.invoiceaddress.lastname }}</p>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.invoiceaddress.mobile }}</p>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.invoiceaddress.street + ' ' +
                    item.invoiceaddress.street2 }}</p>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.invoiceaddress.postcode + ' , ' +
                    item.invoiceaddress.city + ' , ' + item.invoiceaddress.country }}
                  </p>
                </v-card-text>
              </v-card>
            </v-menu>
          </td>
          <td>
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="flat" v-bind="props">
                  <span v-if="item.deliveryaddress != null">
                    {{ (item.deliveryaddress.companyname == null || item.deliveryaddress.companyname.length == 0) ?
                      item.deliveryaddress.firstname + ' ' + item.deliveryaddress.lastname : (
                        item.deliveryaddress.companyname.length > 30 ? (item.deliveryaddress.companyname.substring(0, 30) +
                          '...') : item.deliveryaddress.companyname) }}
                  </span>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card elevation="16" variant="outlined" style="border-radius: 10px;">
                <v-card-text>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.deliveryaddress.firstname + ' ' +
                    item.deliveryaddress.lastname }}</p>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.deliveryaddress.mobile }}</p>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.deliveryaddress.street + ' ' +
                    item.deliveryaddress.street2 }}</p>
                  <p class="text-subtitle-2" style="margin-bottom: 5px;">{{ item.deliveryaddress.postcode + ' , ' +
                    item.deliveryaddress.city + ' , ' + item.deliveryaddress.country }}
                  </p>
                </v-card-text>
              </v-card>
            </v-menu>
          </td>
          <td>
            {{ item.currency.symbol + item.amount.toFixed(2) }}
          </td>
          <td>
            <p v-for="(deliveryNote, key) in item.deliveryNotes" :key="key">
              <router-link class="font-sm-weight-bold" :to="'/deliverynote/' + deliveryNote.id">{{
                deliveryNote.deliverynr
              }}</router-link>
            </p>
          </td>
          <!-- <td>
            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-alt-secondary">
                <i class="fa fa-fw fa-pencil-alt"></i>
              </button>
              <button type="button" class="btn btn-sm btn-alt-secondary">
                <i class="fa fa-fw fa-times"></i>
              </button>
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <div>
    <v-table fixed-header class="text-body-2">
      <thead>
        <tr>
          <th class="text-center" style="width: 100px">
            Channel
          </th>
          <th>Order ID</th>
          <th>Date</th>
          <th>Invoice Address</th>
          <th>Delivery Address</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in this.$props.recentOrders" :key="index">
          <td class="text-center">
            {{ item.channel }}
          </td>
          <td class="text-center">
            <router-link :to="'/order/' + item.id">
              {{ item.ordernr }}<br>
              {{ item.orderextnr }}
            </router-link>
          </td>
          <td class="fw-semibold fs-sm">
            {{ item.orderdate }}
          </td>
          <td class="text-left">
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="flat" v-bind="props">
                  <span v-if="item.invoiceaddress != null">
                    {{ (item.invoiceaddress.companyname == null || item.invoiceaddress.companyname.length == 0) ?
                      item.invoiceaddress.firstname.substring(0, 30) + ' ' + item.invoiceaddress.lastname : (
                        item.invoiceaddress.companyname.length > 30 ? (item.invoiceaddress.companyname.substring(0, 30) +
                          '...') : item.invoiceaddress.companyname) }}
                  </span>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card elevation="16" variant="outlined" style="border-radius: 10px;">
                <v-card-text>
                  <p class="text-subtitle-1">Name: {{ item.invoiceaddress.firstname + ' ' +
                    item.invoiceaddress.lastname }}
                  </p>
                  <p class="text-subtitle-1">Mobile: {{ item.invoiceaddress.mobile }}</p>
                  <p class="text-subtitle-1">Address 1: {{ item.invoiceaddress.street }}</p>
                  <p class="text-subtitle-1">Address 2: {{ item.invoiceaddress.street2 }}</p>
                  <p class="text-subtitle-1">City: {{ item.invoiceaddress.city }}</p>
                  <p class="text-subtitle-1">Country: {{ item.invoiceaddress.country }}</p>
                </v-card-text>
              </v-card>
            </v-menu>
          </td>
          <td class="text-left">
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="flat" v-bind="props">
                  <span v-if="item.deliveryaddress != null">
                    {{ (item.deliveryaddress.companyname == null || item.deliveryaddress.companyname.length == 0) ?
                      item.deliveryaddress.firstname + ' ' + item.deliveryaddress.lastname : (
                        item.deliveryaddress.companyname.length > 30 ? (item.deliveryaddress.companyname.substring(0, 30) +
                          '...') : item.deliveryaddress.companyname) }}
                  </span>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card elevation="16" variant="outlined" style="border-radius: 10px;">
                <v-card-text>
                  <p class="text-subtitle-1">Name: {{ item.deliveryaddress.firstname + ' ' +
                    item.deliveryaddress.lastname }}</p>
                  <p class="text-subtitle-1">Mobile: {{ item.deliveryaddress.mobile }}</p>
                  <p class="text-subtitle-1">Address 1: {{ item.deliveryaddress.street }}</p>
                  <p class="text-subtitle-1">Address 2: {{ item.deliveryaddress.street2 }}</p>
                  <p class="text-subtitle-1">City: {{ item.deliveryaddress.city }}</p>
                  <p class="text-subtitle-1">Country: {{ item.deliveryaddress.country }}</p>
                </v-card-text>
              </v-card>
            </v-menu>
          </td>
          <td>
            {{ item.currency.symbol + item.amount.toFixed(2) }}
          </td>
          <td>
            <p v-for="(deliveryNote, key) in item.deliveryNotes" :key="key">
              <router-link class="font-sm-weight-bold" :to="'/deliverynote/' + deliveryNote.id">{{
                deliveryNote.deliverynr
              }}</router-link>
            </p>
          </td>
          <td class="fs-sm">
            <span
              :class="`fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-${item.articletype}-light text-${item.articletype}`"
            >
              {{ item.amount }}
            </span>
          </td>
          <td class="text-center">
            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-alt-secondary">
                <i class="fa fa-fw fa-pencil-alt"></i>
              </button>
              <button type="button" class="btn btn-sm btn-alt-secondary">
                <i class="fa fa-fw fa-times"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div> -->
</template>
<script>
import moment from 'moment';
export default {
  props: ['recentOrders'],
  data() {
    return {

    }
  },
  methods: {
    formateDate(date) {
      if (date) {
        return moment(String(date)).format('lll')
      }

    },
  }
}
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}

.table th,
.table td {
  white-space: nowrap;
  text-transform: none;
}

.centered-table th,
.centered-table td {
  /* Center align text */
  vertical-align: middle;
  /* Center align vertically */
  padding: 0.125rem;
  /* Adjust the padding as desired */
}
</style>