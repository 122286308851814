<!-- <template>
  <div>
    <apexchart height="230" type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
export default {
  props: ['ordersCount'],
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        }
      },
      series: [{
        name: 'Order Count',
        data: []
      }]
    }
  },
  mounted() {
    if (!this.$store.state.loading) {
      this.series[0].data = []
      this.$props.ordersCount.forEach(element => {
        this.series[0].data.push(element.count)
      });
    }
  },
}
</script> -->

<template>
  <div>
    <apexchart height="230" type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    ordersCount: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        }
      }
    }
  },
  computed: {
    series() {
      const data = new Array(12).fill(0);
      this.ordersCount.forEach(element => {
        if (element.month && element.count) {
          data[element.month - 1] = element.count;
        }
      });
      return [{
        name: 'Order Count',
        data: data
      }];
    }
  }
}
</script>
