export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到 Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里创建您的免费账户"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成注册"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税号"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["街道"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半径"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意网站的条款和条件"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的账户已创建。请检查您的电子邮件以验证您的账户。"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择客户组"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商们"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商订单"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代发货"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制造商"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工匠"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承运商"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理商"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集成商"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该字段为必填项"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营业执照"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录您的账户"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密码"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有与此电子邮件关联的账户"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码无效"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货地址"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票地址"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单日期"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发货日期"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货备注"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仓库"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["库存"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["称谓"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货地址"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输详情"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输方式"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输方式"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承运商"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发货日期"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承运商详情"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章特征"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房子"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货位置"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹移除"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用地点"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时服务 周一至周五"])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时服务 周六"])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48小时服务"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按指定日期送货 周一至周五"])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按指定日期送货 周六"])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下班后送货服务"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速组装服务"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输方式"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转运点"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通运输"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过程"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹追踪"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件数"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存运输数据"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存地址"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输选项"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在仓库提货"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追踪和跟踪"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输位置"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女士"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先生"])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建发货订单"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存地址"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的接口"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已用接口"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用接口"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预订"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配置"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始同步"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["值"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选项"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新日期"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来源值"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态类型"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章创建"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产者"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仓库"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退货"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["值"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发货"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未发货"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加映射后保存件数"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里保存映射"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy 管理员"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分析"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追踪和跟踪"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近添加"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待定"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已送达"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有问题的包裹"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退货"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本周"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上周"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去7天"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去14天"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去30天"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上个月"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去3个月"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去6个月"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去12个月"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未准备"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已退货"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货异常"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["问题"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追踪号码"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承运商（参考）"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近事件"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近信息"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加日期"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未应用筛选器"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近14天的概览"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包裹已添加"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顶级承运商（基于体积）"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待处理订单"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利润"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天的订单"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天的收入"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接成功"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发货日志"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签已创建"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文章类型"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章概览"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本数据"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章名称"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品描述"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物流数据"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图片"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步文章"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章项目描述模板"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新模板"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存模板"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章项目"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加文章项目"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存项目"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商物品名称"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未设置筛选器"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总运输量"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用 Microsoft 365 登录"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合同概览"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加合同"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合同名称"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期自"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效期至"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必需"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否活跃"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否必需"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户组"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存合同"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择活跃状态"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择必需状态"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不必需"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑合同"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新合同"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合同详情"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标记为已读"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载更多"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择语言"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步订单"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择商店"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单日期"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履行状态"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货方式"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加供应商"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代发货"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接口"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商名称"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按供应商名称搜索"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商订单总数"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下降"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打印"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML模板构造器"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预览"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建者"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新者"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章特征值"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单概览"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近15个订单"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章详情"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章捆绑"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成标签"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载标签"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承运商"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输方式"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出库状态"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超级管理员"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建发货"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待中"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑出库"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建履行"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选问题"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批量更新发货"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发货概览"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用更新订单"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择类型"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择履行状态"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已履行"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未履行"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择追踪状态"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标识码"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货编号"])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部参考ID"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新订单"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新发货"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用履行订单"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履行订单"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未创建"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已确认"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理中"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发货"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除筛选器"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除筛选器"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期筛选器"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选依据"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总发货量"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人概览"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户编号"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户类别"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户组"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加渠道SKU"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["件数数据"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加件数"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑件数"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仓库商品"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新缓冲区"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总数量"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用数量"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后更新于"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加订单"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考ID"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商订单编号"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择物品"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建订单"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供应商地址"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入库状态"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建入库"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入库ID"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品图片"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同步图片"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货单"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货状态"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传图片"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物品编号"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新订单"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新外部订单"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打印订单"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空闲位置"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不需要发货"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的运输方式"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税费"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系信息"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送货地址"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择图片"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择所有图片"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除图片"])}
}