export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea tu cuenta gratuita aquí"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar registro"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la empresa"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto los términos y condiciones del sitio"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta ha sido creada. Por favor revisa tu correo electrónico para verificar tu cuenta."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éxito"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar grupo de clientes"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Órdenes de proveedor"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricante"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artesano"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportista"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencia"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrador"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo requerido"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro comercial"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión en tu cuenta"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay una cuenta asociada con este correo electrónico"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña inválida"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de entrega"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de facturación"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del pedido"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de envío"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas de entrega"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacén"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventario"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saludo"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de entrega"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de envío"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de envío"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de envío"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportista"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de envío"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del transportista"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características del artículo"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación de entrega"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro de paquete"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de uso"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio 24h Lun.-Vie."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio 24h Sáb."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio 48h"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega en fecha deseada Lun.-Vie."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega en fecha deseada Sáb."])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de entrega después del trabajo"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio de montaje exprés"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de envío"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de transferencia"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío normal"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquetes"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colis"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar datos de envío"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar dirección"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de envío"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogida en el depósito"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento para"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiciones de envío"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sra."])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconocido"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sr."])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverso"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear orden de envío"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar dirección"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis interfaces"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaces usadas"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaces disponibles"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservar"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sincronización"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opción"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuente"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor de la fuente"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de estado"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de artículos"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricante"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacén"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devoluciones"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No enviado"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarde el colis después de agregar el mapeo"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar mapeo aquí"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilla"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío para"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track & Trace"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíos"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recientemente añadido"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregado"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete con problema"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devoluciones"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta semana"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semana pasada"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 7 días"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 14 días"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 30 días"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mes"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mes pasado"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 3 meses"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 6 meses"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 12 meses"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No preparado"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devuelto"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excepción de entrega"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de seguimiento"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportista (Referencia)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último evento"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última información"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de añadido"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se aplicaron filtros"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de los últimos 14 días"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete añadido"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principales transportistas (basado en volumen)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Órdenes pendientes"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancia"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Órdenes de hoy"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancias de hoy"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión exitosa"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registros de envío"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta creada"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tipo de artículo"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen del artículo"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos principales"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del artículo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del artículo"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos logísticos"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar artículos"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas de descripción del proyecto de artículos"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva plantilla"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto de artículo"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar proyecto de artículo"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar proyecto"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del artículo del proveedor"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha establecido ningún filtro"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíos totales"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen del contrato"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar contrato"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del contrato"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido desde"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válido hasta"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requerido"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está activo"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es requerido"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de clientes"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar contrato"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estado activo"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estado requerido"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No requerido"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar contrato"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar contrato"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del contrato"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como leído"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar más"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar idioma"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar órdenes"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tienda"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de pedido"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del pedido"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de cumplimiento"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de entrega"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de entrega"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar proveedor"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del proveedor"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaz"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del proveedor"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por nombre del proveedor"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Órdenes totales del proveedor"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de fecha"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constructor de plantillas HTML"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado por"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado por"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor de características del artículo"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de pedidos"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 15 pedidos"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del artículo"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripciones"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquete de artículos"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar etiqueta"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar etiquetas"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportistas"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de envío"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de salida"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear envío"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En espera"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar salida"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cumplimiento"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar problemas"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización masiva de envío"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de envío"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar actualización de pedido"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tipo"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estado cumplido"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumplido"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cumplido"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estado de seguimiento"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de identificación"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de entrega"])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de referencia externa"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar pedido"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar envío"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar pedido cumplido"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumplir pedido"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No creado"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abierto"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmado"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar filtros"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar filtro"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro de fecha"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrado por"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíos totales"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de contacto"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cliente"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría de cliente"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de clientes"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar SKU de canal"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de colis"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar colis"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar colis"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos en almacén"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar búfer"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad total"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad disponible"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última actualización"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar pedido"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de referencia"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de orden del proveedor"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar artículos"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear pedido"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del proveedor"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de entrada"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear entrada"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de entrada"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen del artículo"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizar imagen"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del pedido"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota de entrega"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de entrega"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir imagen"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de artículo"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar pedido"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar pedido externo"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir pedido"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiciones libres"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío no requerido"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay tipo de envío disponible"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de contacto"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de envío"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar imágenes"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar todas las imágenes"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar imágenes"])}
}