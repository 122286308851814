export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer dig"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret din gratis konto her"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldfør registreringen"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprog"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternavn"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmanavn"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momsnummer"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gade"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radius"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg accepterer webstedets vilkår og betingelser"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din konto er blevet oprettet. Tjek din e-mail for at bekræfte din konto."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg kundegruppe"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandør"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandører"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandørordrer"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håndværker"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportør"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bureau"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrator"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltet er påkrævet"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virksomhedsregistrering"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind på din konto"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glemt dit kodeord?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ingen konto tilknyttet denne e-mail"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig adgangskode"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadresse"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktureringsadresse"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestilling"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillinger"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillingsdato"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelse"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsesdato"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsnotater"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstillinger"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerbeholdning"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilsen"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadresse"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsesdetaljer"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsestype"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsestyper"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragtmand"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsesdato"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragtmandsdetaljer"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeltræk"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hus"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringssted"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjernelse af pakke"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugssted"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-timers service ma.-fr."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-timers service lø."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48-timers service"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering på ønsket dato ma.-fr."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering på ønsket dato lø."])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efterarbejde leveringstjeneste"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekspres monteringstjeneste"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsesmetode"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overførselssted"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal forsendelse"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakkesporing"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakker"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolli"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem forsendelsesdata"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem adresse"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsesmuligheder"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhentning på depot"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spor og følg for"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsespositioner"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fru"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukendt"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hr."])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret forsendelsesordre"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem adresse"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine grænseflader"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugte grænseflader"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgængelige grænseflader"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurer"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start synkronisering"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabel"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værdi"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulighed"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidst opdateret"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kildeværdi"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statustype"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlinger"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakter"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikler"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelse af artikel"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returneringer"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værdi"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsendt"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke afsendt"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem kolli efter tilføjelse af kortlægning"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem kortlægning her"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabelon"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelse for"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugere"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ud"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spor & Følg"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelse"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelser"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyligt tilføjet"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afventer"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveret"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakke med problem"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returneringer"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dag"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I går"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne uge"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste uge"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste 7 dage"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste 14 dage"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste 30 dage"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne måned"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste måned"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste 3 måneder"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste 6 måneder"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste 12 måneder"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke forberedt"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returneret"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsundtagelse"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporingsnummer"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportør (Reference)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste hændelse"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste info"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato tilføjet"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen filtre anvendt"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt over de sidste 14 dage"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakke tilføjet"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top transportører (baseret på volumen)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventende ordrer"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordrer i dag"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtjening i dag"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestilling"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbindelse lykkedes"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelseslogfiler"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besked"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label oprettet"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg artikeltype"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelsammendrag"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisoplysninger"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnavn"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbeskrivelse"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistikdata"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priser"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billeder"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser artikler"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabeloner til artikelprojekter"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret ny skabelon"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem skabelon"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skabelonnavn"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelprojekt"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj artikelprojekt"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem projekt"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandørens artikelnavn"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen filter indstillet"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlede forsendelser"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind med Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraktsoversigt"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj kontrakt"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraktnavn"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyldig fra"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyldig til"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Påkrævet"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er aktiv"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er påkrævet"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundegruppe"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem kontrakt"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg aktiv status"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg påkrævet status"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke påkrævet"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kontrakt"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater kontrakt"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraktdetaljer"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luk"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markér som læst"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæs mere"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg sprog"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser ordrer"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg butik"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillings-ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillingsdato"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beløb"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udførelsesstatus"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverings-ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsmetode"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj leverandør"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandør-ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Websted"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grænseflade"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandørnavn"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter leverandørnavn"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlede leverandørordrer"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato-ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udskriv"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML-skabelonbygger"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandør"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettet af"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdateret af"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeltrækværdi"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordreoversigt"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste 15 ordrer"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeldetaljer"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelser"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbundt"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistikker"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret label"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download labels"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragtmænd"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsestype"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgående status"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butik"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varer"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret forsendelse"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På hold"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger udgående forsendelse"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret opfyldelse"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer problemer"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masseopdatering af forsendelse"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsesoversigt"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver opdatering af ordre"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg type"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg udførelsesstatus"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldført"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke fuldført"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg sporingsstatus"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identkode"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsnr."])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstern reference-ID"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater ordre"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater forsendelse"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver opfyldelse"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldfør ordre"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke oprettet"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åben"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftet"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under behandling"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsendt"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryd filtre"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryd filter"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato filter"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreret efter"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlede forsendelser"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktoversigt"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenummer"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundekategori"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundegruppe"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj kanal-SKU"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolli data"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj kolli"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger kolli"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerartikler"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater buffer"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet mængde"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgængelig mængde"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidst opdateret"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj ordre"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference-ID"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandørens ordrenummer"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg artikler"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret ordre"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverandørens adresse"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indgående status"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret indgående forsendelse"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indgående ID"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdig"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbillede"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkroniser billede"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordrestatus"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsnotat"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsstatus"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload billede"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater ordre"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater ekstern ordre"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udskriv ordre"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frie positioner"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelse ikke påkrævet"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen forsendelsestype tilgængelig"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjernet"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabat"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatter"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinformation"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadresse"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg billeder"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg alle billeder"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet billeder"])}
}