<template>
  <div v-if="contractLoading" class="d-flex justify-content-center">
    <div class="spinner-border text-warning" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="contracts != null">
    <div class="alert alert-danger alert-dismissible ma-2" role="alert" v-for="(contract, index) in contracts"
      :key="index">
      <span class="mb-0">
        You must accept the contract {{ contract.contract.name }} by clicking
        <button class="btn btn-link" @click="showContractModal(contract)" :style="{ color: linkColor }"
          data-bs-toggle="modal" data-bs-target="#acceptModal">Accept
          Contract</button>
      </span>
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>
  <v-row :style="theme.global.name == 'light' ? 'height:100%;background-color:aliceblue' : 'height:100%'">
    <v-col cols="12">
      <v-row class="ma-2">
        <v-col lg="3" cols="12">
          <v-card elevation="0">
            <v-card-title style="color:blue;" class="text-center text-h4 mt-4">35</v-card-title>
            <v-card-actions class="mt-5"
              :style="theme.global.name == 'light' ? 'background-color:rgb(237, 237, 237)' : ''">
              <v-spacer></v-spacer>
              <v-btn size="" variant="text">{{ $t('pendingorders') }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="3" cols="12">
          <v-card elevation="0">
            <v-card-title style="color:green;" class="text-center text-h4 mt-4">33%</v-card-title>
            <v-card-actions class="mt-5"
              :style="theme.global.name == 'light' ? 'background-color:rgb(237, 237, 237)' : ''">
              <v-spacer></v-spacer>
              <v-btn size="" variant="text">{{ $t('profit') }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="3" cols="12">
          <v-card elevation="0">
            <v-card-title class="text-center text-h4 mt-4">{{ dashboardDetails.today_orders_count }}</v-card-title>
            <v-card-actions class="mt-5"
              :style="theme.global.name == 'light' ? 'background-color:rgb(237, 237, 237)' : ''">
              <v-spacer></v-spacer>
              <v-btn size="" variant="text">{{ $t('orderstoday') }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="3" cols="12">
          <v-card elevation="0">
            <v-card-title class="text-center text-h4 mt-4">€{{ dashboardDetails.todays_earning }}</v-card-title>
            <v-card-actions class="mt-5"
              :style="theme.global.name == 'light' ? 'background-color:rgb(237, 237, 237)' : ''">
              <v-spacer></v-spacer>
              <v-btn size="" variant="text">{{ $t('earningstoday') }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- <shippersData v-if="!this.loading" :dateArray="dateArray" :shippingCount="shippingCounts"
          :seriesData="seriesData" :shippersCount="shippersCount"></shippersData> -->
          <shippersData></shippersData>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="ma-10" elevation="0">
            <v-card-actions>
              <span class="text-title ml-3">
                {{ $t('orderOverview') }}
              </span>
              <v-spacer></v-spacer>
              <v-icon>mdi-refresh</v-icon>
            </v-card-actions>
            <orderOverview v-if="!this.loading" :ordersCount="monthOrderCount"></orderOverview>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="ma-5" elevation="0">
            <v-card-title>
              {{ $t('last15Order') }}
            </v-card-title>
            <v-card-text>
              <orderTable :recentOrders="recentOrders"></orderTable>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <div class="modal fade" id="acceptModal" tabindex="-1" aria-labelledby="acceptModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <BaseBlock :title="selectedContract ? selectedContract.contract.name : ''" transparent class="mb-0">
          <template #options>
            <button type="button" class="btn-block-option" data-bs-dismiss="modal" aria-label="Close">
              <i class="fa fa-fw fa-times"></i>
            </button>
          </template>
          <template #content>
            <div class="block-content fs-sm" v-html="selectedContract ? selectedContract.content : ''">
            </div>
            <div class="block-content block-content-full text-end bg-body">
              <button type="button" class="btn btn-sm btn-alt-secondary me-1" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" class="btn btn-sm btn-primary text-white" data-bs-dismiss="modal"
                @click="acceptDocument(selectedContract)">
                Accept Document
              </button>
            </div>
          </template>
        </BaseBlock>
      </div>
    </div>
  </div>
  <v-dialog persistent="" v-model="loading" class="text-center">
    <div class="text-center">
      <v-progress-circular indeterminate size="80" color="orange-darken-2">
      </v-progress-circular>
    </div>
  </v-dialog>
</template>
<script>
import { APIService } from '../Utils/APIService'
const apiService = new APIService()
import Theme from '../Utils/Theme.js'
import NavaigationDrawer from '../components/NavigationDrawer.vue';
import OrderTable from '../components/RecentOrder.vue';
import TopProduct from '../components/TopProduct.vue';
import OrderOverview from '../components/OrderOverview.vue';
import ShippersData from '../components/ShippersData.vue';
import { useTheme } from 'vuetify/lib/framework.mjs';
export default {
  components: {
    'navigationDrawer': NavaigationDrawer,
    'orderTable': OrderTable,
    'topProduct': TopProduct,
    'orderOverview': OrderOverview,
    'shippersData': ShippersData
  },
  data() {
    return {
      themeColorGrey: Theme.themeColorGrey,
      buttonColor: Theme.buttonColor,
      theme: useTheme(),
      dashboardDetails: {},
      monthOrderCount: [],
      shippingCounts: {},
      seriesData: [],
      shippersCount: [],
      recentOrders: [],
      loading: true,
      errors: [],
      dateArray: [],
      isModalVisible: false,
      linkColor: 'blue',
      contracts: [],
      selectedContract: null,
      contractLoading: false,
    }
  },
  mounted() {
    this.getDashboardDetails()
    this.fecthClientContracts()
  },
  methods: {
    showContractModal(contract) {
      this.selectedContract = contract;
      // console.log(this.selectedContract);
    },
    checkAuth() {
      apiService.checkAuth().then((response) => {
        this.$store.state.userDetails.name = response.data.name
        this.$store.state.token = response.data.token
        this.$store.state.userDetails.email = response.data.email
        this.$store.state.userDetails.companyName = response.data.companyName
        this.$store.state.isAuthenticated = true
      })
        .catch(e => {
          this.errors.push(e)
          if (this.errors[0].response.status) {
            sessionStorage.removeItem('jwt')
            this.$router.push('/')
          }
        })
    },
    getDashboardDetails() {
      this.loading = true
      const dashboardJson = {}
      apiService.getDashboardReport(dashboardJson).then((response) => {
        // console.log(response.data.data)
        this.dashboardDetails = response.data.data
        this.monthOrderCount = response.data.data.month_orders_count
        // this.shippingCounts = response.data.data.shipping_count
        // this.seriesData = response.data.data.series_data
        // this.shippersCount = response.data.data.shippers_count
        // this.dateArray = response.data.data.date_array
        this.recentOrders = response.data.data.recent_orders
        this.loading = false
        this.$store.state.loading = false
      })
        .catch(e => {
          if (e.response && e.response.status === 401) {
                    this.logoutUser();
                } else {
                  this.$store.state.loading = false
                    this.errors.push(e);
                    this.loading = false;
                }
        })
    },
    fecthClientContracts() {
      this.contractLoading = true
      apiService.fetchClientContracts().then((response) => {
        if (response.status === 200) {
          this.contracts = response.data.data.client_contracts;
          // console.log(this.contracts);
          this.contractLoading = false;
        }
      }).catch(e => {
                    this.errors.push(e);
                    this.contractLoading = false
      });
    },
    getFormattedDateTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    acceptDocument(selectedContract) {
      console.log(selectedContract);
      this.loading = true;
      const docJson = {};
      docJson.document_id = selectedContract.id;
      const currentDate = this.getFormattedDateTime();
      docJson.signed = currentDate;
      apiService.acceptDocument(docJson).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          this.loading = false;
          this.$notify({
            title: "Success",
            text: "Contract successfully accepted",
            type: "success",
          });
          const modal = new bootstrap.Modal(document.getElementById('acceptModal'));
          modal.hide();
          window.location.reload();
        }
      }).catch(e => {
        this.loading = false;
        this.errors.push(e);
      });
    },
    logoutUser() {
            this.$store.state.token = null
            sessionStorage.removeItem('jwt')
            sessionStorage.removeItem('language')
            this.$store.state.isAuthenticated = false
            this.$store.state.userDetails.name = null
            this.$store.state.userDetails.email = null
            this.$store.state.userDetails.companyName = null
            this.$router.push({ path: '/' })
        },
  }
}
</script>