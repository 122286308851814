export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Add Option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "check connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "interface statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "interface options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "jobber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने के लिए"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैकेज जोड़ा गया"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शीर्ष प्रेषक (आधारित आवृत्ति पर)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुले आदेश"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाभ"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज के आदेश"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज की बिक्री"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने का लॉग"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेबल बनाया गया"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख सूची"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल डेटा"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आर्टिकल का नाम"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वस्तु विवरण"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉजिस्टिक डेटा"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चित्र"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपूर्तिकर्ता आइटम का नाम"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  }
}