import store from '../store'
import GlobalVariable from './GlobalVariable';
class Theme {

  constructor() {
    this.updateThemeColors();
  }
  fwf() {
    this.textFieldColor = '#58c0fa';
    this.buttonColor = '#00008B';
    this.textFieldVarient = 'outlined';
    this.themeColor = '#00ADEF';
    this.themeColorOrange = '#1C3790';
    this.themeColorGrey = '#545454';
    this.langaugeButtonColor = '#D3D3D3';
  }
  dropy() {
    this.textFieldColor = '#58c0fa';
    this.buttonColor = '#ffbe01';
    this.textFieldVarient = 'outlined';
    this.themeColor = '#0ac3ff';
    this.themeColorOrange = '#0ac3ff';
    this.themeColorGrey = '#545454';
    this.langaugeButtonColor = '#D3D3D3';
  }
  default() {
    this.textFieldColor = '#58c0fa';
    this.buttonColor = 'red-accent-2';
    this.textFieldVarient = 'outlined';
    this.themeColor = 'green';
    this.themeColorOrange = '#1C3790';
    this.themeColorGrey = '#545454';
    this.langaugeButtonColor = '#D3D3D3';
  }
  updateThemeColors() {
    console.log(GlobalVariable.themeType)
    if (GlobalVariable.themeType == 'FWF') {
      this.fwf()
    } else if ('Dropy') {
      this.dropy()
    } else {
      this.default()
    }
  }
}

export default (new Theme())