<template>
    <v-table height="300px" fixed-header>
      <thead>
        <tr>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in desserts"
          :key="item.name"
        >
          <td>
            <span class="font-weight-bold">{{ 'PID.' +  item.pid }}</span>
            </td>
          <td>{{ item.name }}</td>
          <td>
            <v-rating
              v-model="item.rating"
              color="white"
              active-color="orange-accent-4"
              half-increments
              readonly=""
              size="18"
            ></v-rating>
            </td>
        </tr>
      </tbody>
    </v-table>
  </template>
  <script>
  export default {
    data () {
      return {
        desserts: [
          {
            name: 'Frozen Yogurt',
            pid: 159,
            rating: 5
          },
          {
            name: 'Ice cream sandwich',
            pid: 237,
            rating: 5
          },
          {
            name: 'Eclair',
            pid: 262,
            rating: 4
          },
          {
            name: 'Cupcake',
            pid: 305,
            rating: 4
          },
          {
            name: 'Gingerbread',
            pid: 356,
            rating: 4
          },
          {
            name: 'Jelly bean',
            pid: 375,
            rating: 4
          },
        ],
      }
    },
  }
</script>