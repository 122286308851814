import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from './i18n'
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from 'vue-sweetalert2';
import Notifications from '@kyvg/vue3-notification'
import 'sweetalert2/dist/sweetalert2.min.css';
import JsonViewer from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";
import BaseBlock from './layouts/BaseBlock.vue';
import BaseNavigation from './layouts/BaseNavigation.vue';
import BasePageHeading from './layouts/BasePageHeading.vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import * as bootstrap from "bootstrap";
import CustomTheme from './Utils/Theme';
window.CustomTheme = CustomTheme
window.bootstrap = bootstrap;
loadFonts()

const app = createApp(App);

app.component("BaseBlock", BaseBlock);
app.component("BaseNavigation", BaseNavigation);
app.component("BasePageHeading", BasePageHeading);


app.use(i18n);
app.use(VueApexCharts);
app.use(router);
app.use(JsonViewer);
app.use(store);
app.use(vuetify);
app.use(Notifications);
app.use(VueSweetalert2);
app.use(CKEditor)
app.mount("#app");



// createApp(App)
//   .use(i18n)
//   .use(VueApexCharts)
//   .use(router)
//   .use(JsonViewer)
//   .use(store)
//   .use(vuetify)
//   .use(Notifications)
//   .use(VueSweetalert2)
//   .mount('#app')
