<template>
  <v-app style="font-family: barlow">
    <navigationDrawer v-if="$store.state.isAuthenticated"></navigationDrawer>
    <appBar v-if="$store.state.isAuthenticated"></appBar>
    <v-main>
      <router-view />
      <v-dialog v-model="isTimerRunning" max-width="600">
        <v-card>
          <v-card-text>You are inactive since last 9 mins. System will logout you in 30
            secs</v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
    <notifications position="top right" classes="my-custom-class vue-notification mt-3" max="3" :ignoreDuplicates="true" duration="10" />
  </v-app>
  <v-dialog max-width="600" v-model="$store.state.clientDialog">
    <v-card>
      <v-card-title> Update Client User </v-card-title>
      <v-card-text>
        <v-text-field label="Search Client" variant="outlined" v-model="firmName"></v-text-field>
        <div v-if="clientLoading" class="text-center">
          <v-progress-circular :size="50" color="amber" indeterminate v-if="clientLoading"></v-progress-circular>
        </div>
        <v-list v-if="clients.length > 0">
          <v-list-subheader>Available Clients</v-list-subheader>

          <v-list-item v-for="(item, i) in clients" :key="i" :value="item" color="orange-darken-4"
            @click="changeUserClient(item)">
            <template v-slot:prepend>
              <p class="mr-4 mb-2">{{ i + 1 }}</p>
            </template>
            <v-list-item-title>{{ item.companyname }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="changeUserClient()" :color="themeColorOrange" variant="flat" >Update</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { APIService } from "./Utils/APIService";
import NavaigationDrawer from "./components/NavigationDrawer.vue";
import AppBar from "./components/AppBar.vue";
import Theme from "./Utils/Theme.js";
import GlobalVariable from "./Utils/GlobalVariable.js";
import axios from "axios";
const apiService = new APIService();
export default {
  name: "App",
  components: {
    navigationDrawer: NavaigationDrawer,
    appBar: AppBar,
  },
  data: () => ({
    errors: [],
    buttonColor: Theme.themeColorOrange,
    timer: 30,
    isTimerRunning: false,
    intervalId: null,
    alertTime: 1000,
    firmName: null,
    clientLoading: false,
    clients: [],
    themeType: 'Dropy'
  }),
  beforeCreate() {
    this.$store.state.themeType = GlobalVariable.themeType
  },
  mounted() {
    if (localStorage.getItem("jwt") != null) {
      this.$el.addEventListener("mousemove", this.resetInactivityTimer);
      this.$el.addEventListener("keydown", this.resetInactivityTimer);
      this.checkAuth();
    } else {
      this.getUserIP();
    }
  },
  watch: {
    firmName(newValue, oldValue) {
      if (newValue != null && newValue != "") this.getClient();
      else {
        this.clients = [];
      }
    },
  },
  methods: {
    resetInactivityTimer() {
      // Reset the inactivity timer when there is user activity
      this.$store.commit("clearInactivityTimer");
      this.isTimerRunning = false;
      clearTimeout(this.intervalId);
      this.intervalId = setTimeout(() => {
        this.isTimerRunning = true;
      }, 1710000);

      this.$store.commit(
        "setInactivityTimer",
        setTimeout(() => {
          const token =
            this.$store.state.token != null
              ? this.$store.state.token
              : localStorage.getItem("jwt");
          const url = `${GlobalVariable.authUrl}/v1/user/logout`;
          axios.get(url, { headers: { jwt: token } });

          this.$store.dispatch("logout");
          localStorage.removeItem("jwt");
          this.$router.push("/");
          window.location.reload();
        }, 1800000)
      ); // 60 seconds of inactivity
    },
    checkAuth() {
      apiService
        .checkAuth()
        .then((response) => {
          this.$store.state.userDetails.name = response.data.name;
          this.$store.state.token = response.data.token;
          this.$store.state.userDetails.email = response.data.email;
          this.$store.state.userDetails.companyName = response.data.companyName;
          this.$store.state.clientDir = response.data.dir;
          this.$store.state.clientId = response.data.clientId;
          this.$store.state.isClient = response.data.is_client;
          this.$store.state.roles = response.data.role;
          this.$store.state.roles.forEach((element) => {
            if (element.guard_name == "superadmin") {
              this.$store.state.isSuperAdmin = true;
            }
          });
          this.getUserFormInfo();
          this.$store.state.isAuthenticated = true;
          this.$store.state.ffn = response.data.ffn;
          this.$store.state.exporto = response.data.exporto;
          this.$store.commit('setParentIds', response.data.parentIds);
        })
        .catch((e) => {
          this.errors.push(e);
          if (this.errors[0].response.status) {
            localStorage.removeItem("jwt");
            this.$router.push("/");
          }
        });
    },
    checkTheme() {
      apiService
        .checkTheme()
        .then((response) => {
          this.$store.state.themeType = response.data
          // console.log(response)
        })
        .catch((e) => {
          this.errors.push(e);
          console.log(this.errors[0])
        });
    },
    getUserFormInfo() {
      apiService
        .getUserFormInfo()
        .then((response) => {
          if (response.status === 200) {
            let userOption = response.data.data.user_option;
            this.$store.state.country = userOption.country;
            // Retrieve the language_iso from session storage
            let storedLanguageIso = localStorage.getItem('language');

            // Check if the session stored value is empty or not
            if (storedLanguageIso) {
              // Check if userOption.language_iso is the same as the stored value
              if (userOption.language_iso === storedLanguageIso) {
                this.$store.state.language = userOption.language_iso;
                this.$i18n.locale = userOption.language_iso;
              } else {
                const formJson = {}
                formJson.country = userOption.country
                formJson.language_id = null
                formJson.language_iso = storedLanguageIso
                formJson.row = userOption.default_rows
                formJson.timezone = userOption.timezone
                formJson.date_format = userOption.date_format
                apiService.saveUserFormInfo(formJson).then((response) => {
                  if (response.status === 200) {
                    this.$store.state.language = storedLanguageIso;
                    this.$i18n.locale = storedLanguageIso;
                    console.log("Language Updated successfully");
                  }
                })
                  .catch(e => {
                    console.log(e)
                    data.errors.push(e)
                  })
              }
            } else {
              this.$store.state.language = userOption.language_iso;
              if (userOption.language_iso != null) {
                this.$i18n.locale = userOption.language_iso;
              } else {
                this.getUserIP();
              }
            }
            this.$store.state.timeZone = userOption.timezone;
            this.$store.state.dateFormat = userOption.date_format;
            this.$store.state.rows = userOption.default_rows;
            this.$store.state.userImageUrl = response.data.data.user_image;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getUserIP() {
      if (this.$store.state.language != null) {
        this.$i18n.locale = this.$store.state.language;
        return;
      }
      apiService
        .getUserIp()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.country == "DE") {
              this.$i18n.locale = "de";
              this.$store.state.localLanguage = "de";
            } else {
              this.$i18n.locale = "en";
              this.$store.state.localLanguage = "en";
            }
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getClient() {
      this.clientLoading = true;
      const clientJson = {};
      clientJson.firm_name = this.firmName;
      apiService
        .searchClient(clientJson)
        .then((response) => {
          if (response.status === 200) {
            this.clients = response.data.data;
            this.clientLoading = false;
          }
        })
        .catch((e) => {
          this.clientLoading = false;
          this.errors.push(e);
        });
    },
    changeUserClient(item) {
      this.clientLoading = true;
      const clientJson = {};
      clientJson.client_id = item.id;
      apiService
        .updateUserClient(clientJson)
        .then((response) => {
          if (response.status === 200) {
            this.clientLoading = false;
            this.$notify({
              title: "Success",
              text: "Client successfully updated",
              type: "success",
            });
            window.location.reload();
          }
        })
        .catch((e) => {
          this.clientLoading = false;
          console.log(e);
          this.errors.push(e);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main";
@import "@/assets/scss/oneui/themes/amethyst";
@import "@/assets/scss/oneui/themes/city";
@import "@/assets/scss/oneui/themes/flat";
@import "@/assets/scss/oneui/themes/modern";
@import "@/assets/scss/oneui/themes/smooth";
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@500&display=swap");
</style>
