class GlobalVariable {
    constructor () {
        this.authUrl = process.env.VUE_APP_AUTH_URL
        this.authWebUrl = process.env.VUE_APP_AUTH_WEB_URL
        this.themeType = process.env.VUE_APP_THEME_TYPE
        this.pimUrl = process.env.VUE_APP_PIM_URL
        this.jobberUrl = process.env.VUE_APP_JOBBER_URL
        this.ormUrl = process.env.VUE_APP_ORM_URL
        this.interfacesUrl = process.env.VUE_APP_INTERFACES_URL
        this.ttUrl = process.env.VUE_APP_TT_URL
        this.ccUrl = process.env.VUE_APP_CC_URL
        this.dmsUrl = process.env.VUE_APP_DMS_URL
        this.dmsWebUrl = process.env.VUE_APP_DMS_WEB_URL
        this.accUrl = process.env.VUE_APP_ACC_URL
        this.mmUrl = process.env.VUE_APP_MM_URL
        this.mmsUrl = process.env.VUE_APP_MMS_URL
        this.shipUrl = process.env.VUE_APP_SHIP_URL
        this.webUrl = process.env.VUE_APP_WEB_URL
        this.conUrl = process.env.VUE_APP_CON_URL
        this.payUrl = process.env.VUE_APP_PAY_URL
        this.reportUrl = process.env.VUE_APP_REPORT_URL
        this.worksUrl = process.env.VUE_APP_WORKS_URL
        this.dataUrl = process.env.VUE_APP_DATA_URL
    }
  }

export default (new GlobalVariable())
