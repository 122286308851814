export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välkommen till Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrera dig"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ditt gratis konto här"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutför registreringen"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnamn"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternamn"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Företagsnamn"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momsnummer"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gata"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radie"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag accepterar webbplatsens villkor"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt konto har skapats. Kontrollera din e-post för att verifiera ditt konto."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Framgång"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj kundgrupp"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantör"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantörer"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantörsbeställningar"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillverkare"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantverkare"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportör"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byrå"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrator"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fältet är obligatoriskt"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Företagsregistrering"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in på ditt konto"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glömt ditt lösenord?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget konto är kopplat till denna e-post"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt lösenord"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransadress"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturaadress"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställning"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställningar"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställningsdatum"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frakt"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktdatum"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransnoteringar"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inställningar"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hälsning"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransadress"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktdetaljer"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktsätt"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktsätt"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktföretag"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktdatum"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktföretagets detaljer"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelattribut"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hus"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransplats"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagning av paket"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användningsplats"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-timmars service mån.-fre."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-timmars service lör."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48-timmars service"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverans på önskat datum mån.-fre."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverans på önskat datum lör."])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransservice efter arbetstid"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expressmonteringstjänst"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktmetod"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Överföringspunkt"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal frakt"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketspårning"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolli"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärd"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara fraktdetaljer"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara adress"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktalternativ"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hämtning på depot"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spårning för"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktpositioner"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fru"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okänd"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Företag"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa fraktorder"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara adress"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mina gränssnitt"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använda gränssnitt"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillgängliga gränssnitt"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boka"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurera"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starta synkronisering"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabell"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värde"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativ"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källa"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast uppdaterad"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källvärde"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statustyp"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärder"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakter"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artiklar"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorier"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelprojekt"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillverkare"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returer"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värde"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickat"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte skickat"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara kollin efter tillägg av mappning"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara mappning här"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mall"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frakt för"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spåra & Följ"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frakt"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frakter"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyligen tillagda"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pågående"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levererad"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket med problem"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returer"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idag"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igår"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna vecka"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förra veckan"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 7 dagarna"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 14 dagarna"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 30 dagarna"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna månad"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förra månaden"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 3 månaderna"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 6 månaderna"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 12 månaderna"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte förberedd"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returnerad"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransundantag"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spårningsnummer"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportör (Referens)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste händelse"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste info"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillagd datum"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga filter tillämpade"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt över de senaste 14 dagarna"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket tillagt"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topptransportörer (baserat på volym)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väntande beställningar"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinst"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställningar idag"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intäkter idag"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställning"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslutning lyckades"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktloggar"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meddelande"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett skapad"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj artikeltyp"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelsammanfattning"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grunddata"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnamn"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbeskrivning"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistikdata"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priser"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronisera artiklar"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mall för artikelprojektbeskrivningar"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ny mall"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara mall"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallnamn"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelprojekt"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till artikelprojekt"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara projekt"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantörens artikelnamn"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inget filter inställt"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antal försändelser"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraktsöversikt"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till kontrakt"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraktsnamn"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giltigt från"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giltigt till"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoriskt"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är aktiv"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är obligatoriskt"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundgrupp"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara kontrakt"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj aktiv status"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj obligatorisk status"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte obligatoriskt"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera kontrakt"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera kontrakt"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraktsdetaljer"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviseringar"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markera som läst"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda mer"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj språk"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronisera beställningar"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj butik"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställnings-ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställningsdatum"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belopp"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullgörandestatus"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverans-ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransmetod"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagg"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till leverantör"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantörs-ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbplats"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gränssnitt"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantörens namn"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök efter leverantörens namn"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antal leverantörsbeställningar"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum-ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kund"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv ut"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML-mallkonstruktör"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsgranska"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säljare"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapad av"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdaterad av"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värde för artikelattribut"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställningsöversikt"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste 15 beställningarna"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeldetaljer"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivningar"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelpaket"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistik"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generera etikett"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner etiketter"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktföretag"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktsätt"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående status"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butik"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artiklar"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa försändelse"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väntar"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera utgående försändelse"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa fullgörande"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrera problem"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massuppdatering av försändelse"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt över försändelser"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivera uppdatering av beställning"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj typ"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj fullgörandestatus"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullgjord"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej fullgjord"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj spårningsstatus"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identkod"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransnr."])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern referens-ID"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera beställning"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera försändelse"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivera fullgörande"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullgör beställning"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte skapad"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öppen"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräftad"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pågående"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickad"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rensa filter"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rensa filter"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämpa"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumfilter"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrerad av"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totala försändelser"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktsammanfattning"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundnummer"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundkategori"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundgrupp"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till kanal-SKU"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollidata"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till kolli"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera kolli"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerartiklar"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera buffert"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total kvantitet"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referens"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillgänglig kvantitet"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast uppdaterad"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till beställning"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referens-ID"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantörens ordernummer"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj artiklar"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa beställning"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverantörens adress"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingående status"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa inkommande försändelse"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande ID"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbild"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronisera bild"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställningsstatus"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransnotering"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransstatus"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp bild"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera beställning"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera extern beställning"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv ut beställning"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fria positioner"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frakt ej nödvändig"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen fraktmetod tillgänglig"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borttagen"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delsumma"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatt"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatter"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinformation"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveransadress"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj bilder"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj alla bilder"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera bilder"])}
}