export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto su Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea qui il tuo account"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account cliente"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita IVA"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strada"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggio"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto i termini di utilizzo del sito web"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account è stato creato. Controlla la tua email per confermare il tuo account."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il gruppo di clienti"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitore"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitori"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini ai fornitori"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produttore"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artigiano"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistico"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenzia"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integratore"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obbligatorio"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione commerciale"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi al conto"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la tua password?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non c'è nessun account collegato a questa email"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password non valida"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di consegna"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di fatturazione"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordine"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ordini"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data dell'ordine"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spedizione"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di spedizione"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bolle di consegna"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["magazzino"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impostazioni"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azione"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saluto"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzo di consegna"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dettagli di spedizione"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spedizioneart"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedizioniarti"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speditore"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data di spedizione"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dettagli del mittente"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche dell'articolo"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CASA"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizionamento della consegna"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimozione del pacchetto"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SÌ"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LUOGO DI UTILIZZO"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVIZIO DI VENTQUATTRO ORE_MO_FR"])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVIZI DI VENTIQUATTRO ORE"])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUARTTYEIGHT_HOUR_SERVICE"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSEGNA_IL_DESIRED_DATE_MO_FR"])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSEGNA_IL_DESIRED_DATE_SA"])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVIZIO DI CONSEGNA DOPO IL LAVORO"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVIZIO DI MONTAGGIO ESPRESSO"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di spedizione"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto di trasferimento"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPEDIZIONE NORMALE"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["processi"])},
  "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tt"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetti"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collie"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azione"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["risparmiare sulla spedizione"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salva indirizzo"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni di spedizione"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RITIRO PRESSO DEPOSITO"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB LOEHNE"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito automatico"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracciamento della spedizione per"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizioni della spedizione"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signora"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signore"])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varie"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea ordine di spedizione"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva indirizzo"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfacce"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue interfacce"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfacce disponibili"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotare"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurare"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salvare"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia allineamento"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabella"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzione"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorgente"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimo aggiornamento"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore di origine"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di stato"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "Add Option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi opzione"])},
  "check connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testare la connessione"])},
  "interface statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfacce statistiche"])},
  "interface options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni dell'interfaccia"])},
  "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area di lavoro"])},
  "jobber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratore occasionale"])},
  "Purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articolo"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di articoli"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produttore"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resi"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spedito"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non inviato"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva il Collie, dopo aver aggiunto l'assegnazione"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva mappatura qui"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello di articolo"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedizione per"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettersi"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segnalazione"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traccia e traccia"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spedizione"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spedizioni"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutta"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recente"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in attesa di"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attiva"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consegnato"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["problema"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ritorna"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggi"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieri"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa settimana"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la settimana scorsa"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 7 giorni"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 14 giorni"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 30 giorni"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questo mese"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lo scorso mese"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 3 mesi"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 6 mesi"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ultimi 12 mesi"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non preparato"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restituito"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eccezione di consegna"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["problema"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numero di tracciatura"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitore (riferimento)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo evento"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultime informazioni"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data aggiunta"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun filtro impostato"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica degli ultimi 14 giorni"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetto aggiunto"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migliori mittenti (basato sul volume)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini aperti"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitto"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini odierni"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturato oggi"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro di spedizione"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta creata"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Tipo di Articolo"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica Articolo"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati principali"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome articolo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'elemento"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati logistici"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizza Articoli"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelli di Descrizione del Progetto Articolo"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo Modello"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva Modello"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del Modello"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progetto Articolo"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Progetto Articolo"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva Progetto"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome articolo del fornitore"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun filtro impostato"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale spedizioni"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica Contratto"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Contratto"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del Contratto"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valido dal"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valido fino al"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesto"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È Attivo"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È Richiesto"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo Clienti"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva Contratto"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Stato Attivo"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inattivo"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Stato Richiesto"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Richiesto"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Contratto"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna Contratto"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli del Contratto"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segna come letto"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica di più"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Lingua"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizza Ordini"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Negozio"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Ordine"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Ordine"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato di Evasione"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Consegna"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di Consegna"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Fornitore"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Fornitore"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito Web"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaccia"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale Ordini Fornitore"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Data"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilascia"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stampa"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costruttore di Modelli HTML"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitore"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato Da"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornato Da"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore delle Caratteristiche dell'Articolo"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica degli Ordini"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimi 15 Ordini"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canale"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'Articolo"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizioni"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetto Articoli"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])}
}