<template>
    <v-card elevation="0">
        <v-row>
            <v-col lg="3" md="3" cols="12">
                <v-card elevation="0" class="ml-10">
                    <v-card-title class="ma-3">{{ $t('OverviewlastDays') }}</v-card-title>
                    <v-card-subtitle class="ml-3 font-weight-bold">{{ $t('parcelAdded') }}</v-card-subtitle>
                    <v-card-title class="text-h4 ma-3">{{ totalShipments }}</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item to="/tt/Delivered">
                                <v-list-item-title>
                                    <p class="font-weight-medium">{{ $t('delivered') }}</p>
                                </v-list-item-title>
                                <template v-slot:append>
                                    <p class="mb-3 font-weight-medium">{{ deliveredOrder }}</p>
                                </template>
                                <v-list-item-title>
                                    <v-progress-linear :rounded="true" v-model="deliveredPercentage" color="green"
                                        height="10"></v-progress-linear>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/tt/Active">
                                <v-list-item-title>
                                    <p class="font-weight-medium">{{ $t('active') }}</p>
                                </v-list-item-title>
                                <template v-slot:append>
                                    <p class="mb-3 font-weight-medium">{{ activeOrder }}</p>
                                </template>
                                <v-list-item-title>
                                    <v-progress-linear :rounded="true" v-model="activePercentage" color="blue"
                                        height="10"></v-progress-linear>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/tt/Issue">
                                <v-list-item-title>
                                    <p class="font-weight-medium">{{ $t('issue') }}</p>
                                </v-list-item-title>
                                <template v-slot:append>
                                    <p class="mb-3 font-weight-medium">{{ issueOrder }}</p>
                                </template>
                                <v-list-item-title>
                                    <v-progress-linear :rounded="true" v-model="issuePercentage" color="red"
                                        height="10"></v-progress-linear>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/tt/Return">
                                <v-list-item-title>
                                    <p class="font-weight-medium">{{ $t('returns') }}</p>
                                </v-list-item-title>
                                <template v-slot:append>
                                    <p class="mb-3 font-weight-medium">{{ returnOrder }}</p>
                                </template>
                                <v-list-item-title>
                                    <v-progress-linear :rounded="true" v-model="returnPercentage" color="grey-darken-4"
                                        height="10"></v-progress-linear>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/tt/Pending">
                                <v-list-item-title>
                                    <p class="font-weight-medium">{{ $t('pending') }}</p>
                                </v-list-item-title>
                                <template v-slot:append>
                                    <p class="mb-3 font-weight-medium">{{ pendingOrder }}</p>
                                </template>
                                <v-list-item-title>
                                    <v-progress-linear :rounded="true" v-model="pendingPercentage" color="grey-darken-2"
                                        height="10"></v-progress-linear>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="6" md="6" cols="12">
                <div id="chart">
                    <v-card elevation="0">
                        <v-card-text>
                            <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
            <v-col lg="3" md="3" cols="12">
                <v-card elevation="0" class="ml-10">
                    <v-card-title class="ma-3">{{ $t('topCarriers') }}</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="(shipper, i) in shippersCount" :key="i">
                                <v-list-item-title>
                                    <p class="font-weight-medium">{{ shipper.name }}</p>
                                </v-list-item-title>
                                <template v-slot:append>
                                    <p class="mb-3 font-weight-medium">{{ shipper.shipping_count }}</p>
                                </template>
                                <template v-slot:prepend>
                                    <v-avatar size="50">
                                        <img v-if="shipper.logo != null" :src="shipper.logo" alt="Logo" class="ma-2"
                                            style="height: 50px;" />
                                    </v-avatar>
                                </template>
                                <v-list-item-title>
                                    <v-progress-linear :rounded="true"
                                        :model-value="calculatePercentage(shipper.shipping_count)" color="green"
                                        height="10"></v-progress-linear>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { APIService } from '@/Utils/APIService';

const apiService = new APIService();
export default {
    // props: ['shippingCount', 'seriesData', 'shippersCount', 'dateArray'],
    data() {
        return {
            loading: false,
            deliveredOrder: 0,
            deliveredPercentage: 0,
            issueOrder: 0,
            issuePercentage: 0,
            returnOrder: 0,
            returnPercentage: 0,
            activeOrder: 0,
            activePercentage: 0,
            pendingOrder: 0,
            pendingPercentage: 0,
            shippersCount: [],
            totalShipments: 0,
            series: [],
            dateArray: [],
            shippingCounts: {},
            errors: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 0,
                        // colors: ['green', 'blue', 'red', 'pink', 'black'],
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        },
                        colors: {
                            backgroundBarColors: []
                        }
                    },
                },
                xaxis: {
                    //   type: 'datetime',
                    opposite: true,
                    categories: [],
                },
                labels: {

                },
                legend: {
                    position: 'right',
                    offsetY: 10,
                    labels: {
                        // colors: ['green', 'blue', 'red', 'pink', 'black'],
                        useSeriesColors: false
                    },
                },
                fill: {
                    opacity: 1
                }
            },
        }
    },
    mounted() {
        this.fetchSeriesData()
        // this.series = this.$props.seriesData
        // this.getLast14Days()
        // this.calculateValues()
    },
    methods: {
        fetchSeriesData() {
            this.loading = true;
            const seriesData = {};
            apiService.getDashboardSeriesData(seriesData).then((response) => {
                if (response.status === 200) {
                    // console.log(response.data.data);
                    this.series = response.data.data.series_data;
                    this.shippersCount = response.data.data.shippers_count;
                    console.log(this.shippersCount);
                    this.dateArray = response.data.data.date_array;
                    this.shippingCounts = response.data.data.shipping_count;
                    // console.log(this.shippingCounts.total_shippings);
                    this.getLast14Days()
                    this.calculateValues()
                    this.loading = false;
                }
            }).catch(e => {
                this.loading = false
                this.errors.push(e)
            });
        },
        calculateValues() {
            // console.log('data:' + this.shippingCounts.total_shippings, this.shippingCounts.issues, this.shippingCounts.return, this.shippingCounts.pending, this.shippingCounts.active, this.shippingCounts.delivered);
            this.totalShipments = this.shippingCounts.total_shippings
            this.issueOrder = this.shippingCounts.issues
            this.returnOrder = this.shippingCounts.return
            this.pendingOrder = this.shippingCounts.pending
            this.activeOrder = this.shippingCounts.active
            this.deliveredOrder = this.shippingCounts.delivered

            this.deliveredPercentage = (this.deliveredOrder / this.totalShipments) * 100
            this.returnPercentage = (this.returnOrder / this.totalShipments) * 100
            this.activePercentage = (this.activeOrder / this.totalShipments) * 100
            this.issuePercentage = (this.issueOrder / this.totalShipments) * 100
            this.pendingPercentage = (this.pendingOrder / this.totalShipments) * 100
        },
        calculatePercentage(value) {
            return (value / this.totalShipments) * 100
        },
        getLast14Days() {
            this.chartOptions = {
                xaxis: {
                    categories: this.dateArray.reverse()
                }
            }

            for (let index = 0; index < this.series.length; index++) {
                let dataLength = 14 - this.series[index].data.length
                for (let i = 0; i < dataLength; i++) {
                    this.series[index].data.unshift(0)
                }

            }
        }
    },
}
</script>